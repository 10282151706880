import { http } from './http';
import qs from 'qs';
import { AccountDetail } from '@/dtos/Account';

export function accountRegister({ email, password, company_name }) {
  return http
    .callApi({
      resource: 'account.register',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          email,
          password,
          company_name,
        },
      },
    })
    .then(e => e.data);
}

export function accountRegisterResend({ email }) {
  return http
    .callApi({
      resource: 'account.resend',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          email,
        },
      },
    })
    .then(e => e.data);
}

export function accountLogin({ email, password }) {
  return http
    .callApi({
      resource: 'account.login',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        body: qs.stringify({
          email,
          password,
        }),
      },
    })
    .then(e => {
      let path = new URL(e.request.responseURL);
      switch (path.pathname) {
        case '/web/account/details':
          return { data: e.data };
          break;
        case '/web/account/activate':
          return { error: 'activate' };
          break;
        case '/web/account/2fa/auth':
          return { error: '2fa' };
          break;
        case '/web/account/login':
          throw new Error('Wrong email or password');
        default:
          return { error: '' };
      }
    });
}

export function accountLogout() {
  return http.callApi({
    resource: 'account.logout',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function accountDetail(): Promise<AccountDetail> {
  return http
    .callApi({
      resource: 'account.detail',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(e => {
      if (!e.data.email) {
        return Promise.reject(new Error('NO_ACCOUNT'));
      }

      return e.data;
    });
}

export function activateAccount(email: string, code: string): Promise<any> {
  return http
    .callApi({
      resource: 'account.activate',
      data: {
        body: {
          email,
          code,
        }
      }
    })
    .then(e => {
      return e.data;
    });
}