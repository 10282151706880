import Vue from 'vue'
import Vuex from 'vuex'
import AccountStore from './modules/account';
import CrypitorPaymentStore from './modules/crypitor_payment';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account: AccountStore,
    crypitor_payment: CrypitorPaymentStore
  },
})
