import { render, staticRenderFns } from "./SidebarHeader.vue?vue&type=template&id=0d01afe0&scoped=true&lang=pug&"
import script from "./SidebarHeader.vue?vue&type=script&lang=ts&"
export * from "./SidebarHeader.vue?vue&type=script&lang=ts&"
import style0 from "./SidebarHeader.vue?vue&type=style&index=0&id=0d01afe0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d01afe0",
  null
  
)

export default component.exports