import CentralLayout from '@/components/layouts/centralLayout/CentralLayout.vue';
import _ from 'lodash';
import { RouteConfig } from 'vue-router';



export default function init(routes: RouteConfig[]) {
  const defaultRoute: RouteConfig = {
    path: '/webhook',
    component: CentralLayout,
    children: [
      {
        path: 'wallet/:coin',
        name: 'wallet',
        component: () => import(/*webpackChunkName: "CurrenciesPage"*/ './currencies/CurrenciesPage.vue'),
        meta: {
          title: 'Wallet | Crypitor - Monitoring The Crypto World',
          requiresAuth: true,
        },
      },
      {
        path: 'detail',
        name: 'webhook_detail',
        component: () => import( './currencies/WebhookDetailPage.vue'),
        meta: {
          title: 'Wallet | Crypitor - Monitoring The Crypto World',
          requiresAuth: true,
        },
      },
    ],
  };

  routes.push(defaultRoute);
}


