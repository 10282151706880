import Vue from 'vue';
import { get } from 'lodash';

Vue.prototype.$popupError = function popupError(error, title) {
  console.log(error);
  let errorText = '';
  errorText = errorText || get(error, 'data.description');
  errorText = errorText || get(error, 'msg');
  errorText = errorText || get(error, 'statusText');
  errorText = errorText || get(error, 'data');
  errorText = errorText || 'Somethings went wrong!';
  return this.$swal({
    type: 'error',
    title: title || 'Oops...',
    text: errorText.toString(),
  });
};

Vue.prototype.$toastError = function toastError(error, title) {
  console.log(error);
  let errorText = '';
  errorText = errorText || get(error, 'data.description');
  errorText = errorText || get(error, 'msg');
  errorText = errorText || get(error, 'statusText');
  errorText = errorText || get(error, 'data');
  errorText = errorText || 'Somethings went wrong!';
  return this.$swal({
    toast: true,
    type: 'error',
    title: title || 'Oops...',
    text: errorText.toString(),
  });
};

Vue.prototype.$popupSuccess = function popupSuccess(msg, title) {
  this.$swal.close();

  return this.$swal({
    type: 'success',
    title: title || 'Success',
    text: msg,
  });
};

Vue.prototype.$toastSuccess = function toastSuccess(msg, title) {
  return this.$swal({
    toast: true,
    type: 'success',
    title: title || 'Success',
    text: msg,
  });
};

Vue.prototype.$popupLoading = function popupLoading(
  promise: Promise<any>,
  msg,
  title,
) {
  let popup = this.$swal({
    title: '',
    text: msg,
    onBeforeOpen: () => {
      this.$swal.showLoading();
    },
  });

  Promise.resolve(promise).finally(() => this.$swal.hideLoading());

  return promise;
};

Vue.prototype.$toastLoading = function toastLoading(
  promise: Promise<any>,
  msg,
  title,
) {
  this.$swal({
    toast: true,
    title: msg || 'Loading ...',
    text: msg,
    onBeforeOpen: () => {
      this.$swal.showLoading();
    },
  });

  Promise.resolve(promise).finally(() => this.$swal.hideLoading());

  return promise;
};
