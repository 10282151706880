

declare interface Set<T> {
  isSuperSet(a: Set<T>): boolean
  union(a: Set<T>): Set<T>
  intersection(a: Set<T>): Set<T>
  difference(a: Set<T>): Set<T>
}



Object.defineProperty(Set.prototype, 'isSuperSet', {
  enumerable: false,
  value: function isSuperSet(subset) {
    for (var elem of subset)
      if (!this.has(elem))
        return false;
    return true;
  }
});


Object.defineProperty(Set.prototype, 'union', {
  enumerable: false,
  value: function union(setB) {
    var union = new Set(this);
    for (var elem of setB)
      union.add(elem);
    return union;
  }
});


Object.defineProperty(Set.prototype, 'intersection', {
  enumerable: false,
  value: function intersection(setB) {
    var _intersection = new Set();
    for (var elem of setB)
      _intersection.add(elem);
    return _intersection;
  }
});


Object.defineProperty(Set.prototype, 'difference', {
  enumerable: false,
  value: function difference(setB) {
    var _difference = new Set(this);
    for (var elem of setB)
      _difference.delete(elem);
    return _difference;
  }
});

