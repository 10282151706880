




































































import CentralHeader from './components/CentralHeader.vue';
import SidebarHeader from './components/SidebarHeader.vue';
import SidebarHeaderV2 from './components/SidebarHeaderV2.vue';
import CentralHeaderV2 from './components/CentralHeaderV2.vue';
import store from '@/store';
import { mapState } from 'vuex';
import { Component, Vue, Watch, Provide } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import { mounted } from '@/utils/decorators/VueTimmer';
import { pendingPlan } from '@/apis/plan.api';
import { Order } from '@/dtos/Account';

@Component({
  components: {
    CentralHeader,
    SidebarHeader,
    SidebarHeaderV2,
    CentralHeaderV2,
  },
})
export default class CentralLayout extends Vue {
  openSidebar = false;

  @Provide()
  accountPendingPlan: Order = null;

  @mounted
  getPendingPlan() {
    return pendingPlan().then(e => {
      this.accountPendingPlan = e;
    });
  }

  get hasPendingPlan(): boolean {
    return (
      this.accountPendingPlan != null &&
      this.accountPendingPlan.payment != null &&
      this.accountPendingPlan.payment.payment_id != null
    );
  }

  clickMenu() {
    this.openSidebar = !this.openSidebar;
  }

  hideMenu() {
    this.openSidebar = false;
  }
}
