import AuthenLayout from '@/components/layouts/authenLayout/AuthenLayout.vue';
import _ from 'lodash';
import { RouteConfig } from 'vue-router';

const Login = () => import(/*webpackChunkName: "Login"*/ './authen/Login.vue');
const VerifyEmailRegister = () => import(/*webpackChunkName: "VerifyEmailRegister"*/ './authen/VerifyEmailRegister.vue');
const Register = () =>
  import(/*webpackChunkName: "Register"*/ './authen/Register.vue');
const RegisterConfirming = () =>
  import(
    /*webpackChunkName: "RegisterConfirming"*/ './authen/RegisterConfirming.vue'
  );
const RegisterConfirmSuccess = () =>
  import(
    /*webpackChunkName: "RegisterConfirmSuccess"*/ './authen/RegisterConfirmSuccess.vue'
  );
const RegisterConfirmFailed = () =>
  import(
    /*webpackChunkName: "RegisterConfirmFailed"*/ './authen/RegisterConfirmFailed.vue'
  );
const ForgotPassword = () =>
  import(/*webpackChunkName: "ForgotPassword"*/ './authen/ForgotPassword.vue');
const ForgotPasswordConfirming = () =>
  import(
    /*webpackChunkName: "ForgotPasswordConfirming"*/ './authen/ForgotPasswordConfirming.vue'
  );
const ResetPassword = () =>
  import(/*webpackChunkName: "ResetPassword"*/ './authen/ResetPassword.vue');

const TwoFactorAuthenPage = () =>
  import(/*webpackChunkName: "TwoFactorAuthenPage"*/ './authen/TwoFactorAuthenPage.vue'); 

export default function init(routes: RouteConfig[]) {
  const defaultRoute: RouteConfig = {
    path: '/',
    component: AuthenLayout,
    children: [
      {
        path: '/',
        redirect: { name: 'home' },
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          title: 'Login | Crypitor - Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
      {
        path: 'register',
        name: 'register',
        component: Register,
        meta: {
          title:
            'Register | Crypitor - Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
      {
        path: 'register/confirming',
        name: 'register_confirming',
        component: RegisterConfirming,
        meta: {
          title:
            'Register Confirming | Crypitor - Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
      {
        path: 'register/confirm/success',
        name: 'register_confirm_success',
        component: RegisterConfirmSuccess,
        meta: {
          title:
            'Register Confirm Success | Crypitor - Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
      {
        path: 'register/confirm/failed',
        name: 'register_confirm_failed',
        component: RegisterConfirmFailed,
        meta: {
          title:
            'Register Confirm Failed | Crypitor - Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
      {
        path: 'password/forgot',
        name: 'forgot_password',
        component: ForgotPassword,
        meta: {
          title:
            'Forgot Password | Crypitor - Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
      {
        path: 'password/email_confirm',
        name: 'forgot_password_confirm',
        component: ForgotPasswordConfirming,
        meta: {
          title:
            'Forgot Password Confirming | Crypitor - Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
      {
        path: 'password/reset',
        name: 'reset_password',
        component: ResetPassword,
        meta: {
          title:
            'Reset Password | Crypitor - Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
      {
        path: 'register/verify',
        name: 'register_verify',
        component: VerifyEmailRegister,
        meta: {
          title:
            'Email Verification | Crypitor - Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
      {
        path: '2fa/verify',
        name: '2fa',
        component: TwoFactorAuthenPage,
        meta: {
          title: 'Two Factor Authentication',
        },
      },
    ],
  };

  routes.push(defaultRoute);
}
