import * as firebase from 'firebase/app';
import 'font-awesome/css/font-awesome.min.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import VueI18n from 'vue-i18n';
// import i18nConfig from './configs/vueI18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import veeValidateConfig from './configs/veeValidate';
import BootstrapVue from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import VueClipboard from 'vue-clipboard2';
import {
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Dialog,
  Button,
  Slider,
  Select,
  Option,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import {
  faSignOutAlt,
  faBars,
  faQuestionCircle,
  faCopy,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  faMedium,
  faTelegram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

locale.use(lang);

Vue.use(BootstrapVue);
Vue.use(Slider);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(VueSweetalert2);
Vue.use(VueClipboard);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Select);
Vue.use(Option);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
importDirectives();
importFilters();
importPolyfills();
importMixins();
importFontawesome();
initFirebase();
veeValidateConfig.apply();

new Vue({
  router,
  store,
  // i18n: i18nConfig.create(),
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');

function importDirectives() {
  const pagesRoutes = require.context('./directives', true, /\.ts$/);
  const keys = pagesRoutes.keys();

  keys.forEach((key: string) => {
    pagesRoutes(key);
  });
}

function importFilters() {
  const filters = require.context('./filters', true, /\.ts$/);
  const keys = filters.keys();

  keys.forEach((key: string) => {
    filters(key);
  });
}

function importPolyfills() {
  const filters = require.context('./polyfill', true, /\.ts$/);
  const keys = filters.keys();

  keys.forEach((key: string) => {
    filters(key);
  });
}

function importMixins() {
  const filters = require.context('./mixins/global', true, /\.ts$/);
  const keys = filters.keys();

  keys.forEach((key: string) => {
    filters(key);
  });
}

function importFontawesome() {
  library.add(faSignOutAlt);
  library.add(faBars);
  library.add(faQuestionCircle);
  library.add(faCopy);
  library.add(faCheck);
  library.add(faTimes);
  library.add(faMedium as any);
  library.add(faTelegram as any);
  library.add(faTwitter as any);
}

function initFirebase() {
  let firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
  };
  try {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  } catch(e) {
    console.error(e);
  }
}
