import Vue, { VNode, VNodeDirective } from 'vue';


const state = new WeakMap()

Vue.directive('loading', {
  bind(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {
    if(binding.value)
      el.classList.add('pre-loading','loading')
    else
      el.classList.remove('pre-loading','loading')
  },
  update(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {
    if(binding.oldValue == binding.value)
      return;
    if(binding.value){
      clearTimeout(state.get(el));
      el.classList.add('pre-loading')
      var animate = requestAnimationFrame(() => {
        void el.offsetWidth
        el.classList.add('loading')
      })
      state.set(el,animate);
    }else{
      clearTimeout(state.get(el));
      cancelAnimationFrame(state.get(el));
      el.classList.remove('loading')
      var timeout = setTimeout(() => {
        el.classList.remove('pre-loading')
      },500);
      state.set(el,timeout);
    }
  },
  unbind(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {
    clearTimeout(state.get(el));
    el.classList.remove('pre-loading','loading')
  },
});