import Vue, { VNode, VNodeDirective } from 'vue';
import _ from 'lodash';

Vue.directive('imgLazyLoad', {
  bind: (el: HTMLElement, binding: VNodeDirective, vnode: VNode) => {
    let observer = new IntersectionObserver(
      (changes, observer) => {
        changes.forEach(change => {
          if (change.isIntersecting) {
            let field = _.get(binding.value, 'field', 'src');
            (el as HTMLImageElement)[field] = el.dataset.src;
            observer.disconnect();
          }
        });
      },
      {
        root: null, // relative to document viewport
        rootMargin: '0px', // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: [0],
      },
    );

    observer.observe(el);
  },
});
