import Vue, { VNode, VNodeDirective } from 'vue';

Vue.directive('classOnShow', {
  bind: (el: HTMLElement, binding: VNodeDirective, vnode: VNode) => {
    let observer = new IntersectionObserver(
      (changes, observer) => {
        changes.forEach(change => {
          if (change.intersectionRatio > 0) {
            el.classList.remove('invisible');
            el.classList.add(...binding.value.class);
            observer.disconnect();
            if (binding.value.cb) {
              binding.value.cb();
            }
          }
        });
      },
      {
        root: null, // relative to document viewport
        rootMargin: '0px', // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: [0],
      },
    );
    
    el.classList.add('invisible');
    observer.observe(el);
  },
});
