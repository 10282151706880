import { http } from './http';
import { PlanDetail, AccountPlanDetail, PreviewOrder, Order, Payment } from '@/dtos/Account';

export function getPaymentDetail(payment_id: string): Promise<Payment> {
  return http
    .callApi({
      resource: "payment.detail",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          payment_id
        }
      }
    }).then(e => e.data)
}

export function getPaymentHistory(request: {from_hex_id: string, limit: number, action: string}) {
  return http
  .callApi({
    resource: "payment.history",
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      body: request
    }
  })
  .then(e => e.data);
}




