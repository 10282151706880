import store from './store';
import Router from 'vue-router';

export function init(router: Router): Promise<any> {
  return registerModules(router);
}

function registerModules(router: Router): Promise<any> {
  return store.dispatch('account/init');
}

export default store;
