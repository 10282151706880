import LandingLayout from '@/components/layouts/landingLayout/LandingLayout.vue';
import { RouteConfig } from 'vue-router';

const LandingPage = () =>
  import(/*webpackChunkName: "LandingPage"*/ './home/LandingPage.vue');
const TermOfUsePage = () =>
  import(/*webpackChunkName: "TermsPage"*/ './terms/TermsPage.vue');
const FAQ = () => import(/*webpackChunkName: "FAQ"*/ './terms/FAQ.vue');
const Pricing = () =>
  import(/*webpackChunkName: "PricingPage"*/ './pricing/PricingPage.vue');
const AccountServicePage = () =>
  import(/*webpackChunkName: "AccountServicePage"*/ './accountService/AccountServicePage.vue');

export default function init(routes: RouteConfig[]) {
  const defaultRoute: RouteConfig = {
    path: '/',
    component: LandingLayout,
    children: [
      {
        path: '/',
        name: 'home',
        component: LandingPage,
        meta: {
          title: 'Crypitor - Monitoring The Crypto World',
        },
      },
      {
        path: 'account-service',
        name: 'account_service',
        component: AccountServicePage,
        meta: {
          title: 'Account Service - Monitoring The Crypto World',
        },
      },
      {
        path: 'toc',
        name: 'toc',
        component: TermOfUsePage,
        meta: {
          title: 'Terms - Monitoring The Crypto World',
        },
      },
      {
        path: 'faq',
        name: 'faq',
        component: FAQ,
        meta: {
          title: 'FAQ - Crypitor',
        },
      },
      {
        path: 'pricing',
        name: 'pricing',
        component: Pricing,
        meta: {
          title: 'Pricing - Crypitor',
        },
      },
      {
        path: 'verify-email-success',
        redirect: {
          name: 'register_confirm_success',
        },
      },
      {
        path: 'verify-email-fail',
        redirect: {
          name: 'register_confirm_failed',
        },
      },
      {
        path: 'reset_password',
        redirect: {
          name: 'reset_password',
        },
      },
    ],
  };

  routes.push(defaultRoute);
}
