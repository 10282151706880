import VeeValidate, { Field } from 'vee-validate';
import Vue from 'vue';

// const NODE_ENV = process.env.NODE_ENV;
export default {
  apply,
};

function apply() {
  Vue.use(VeeValidate, config);
  extendValidator();
}

const config = {
  errorBagName: 'veeErrors',
  fieldsBagName: 'veeFields',
  locale: 'en',
  events: 'input|blur',
  classes: true,
  // classes: false,
  classNames: {
    invalid: 'is-danger',
  },
  dictionary: initDictionaryConfig(),
};

function extendValidator() {
  // VeeValidate.Validator.extend('password', function(value) {
  //   return (
  //     value &&
  //     /^.{8,128}$/.test(value)
  //     // /[A-Z]/.test(value) &&
  //     // /[a-z]/.test(value) &&
  //     // /[0-9]/.test(value) &&
  //     // /^.{10,128}$/.test(value) &&
  //     // /[^a-z0-9]/i.test(value) &&
  //     // /^(?!.*(.)\1\1)/.test(value)
  //   );
  // });

  // VeeValidate.Validator.extend('gt_value', (value: string, gtValue: number) => {
  //   const valueNumber = parseFloat(value);
  //   return value && valueNumber && valueNumber > gtValue;
  // });

  // VeeValidate.Validator.extend('lt_value', (value: string, ltValue: number) => {
  //   const valueNumber = parseFloat(value);
  //   return value && valueNumber && valueNumber < ltValue;
  // });

  // // 2FA Code rule
  // VeeValidate.Validator.extend('2fa_code', function(value, gtValue) {
  //   return value && parseInt(value) && value.toString().length === 6;
  // });

  // // ERC20 Address
  // VeeValidate.Validator.extend('erc20_address', function(value) {
  //   return value && value.length === 42 && /0[xX][a-zA-Z0-9]+/.test(value);
  // });
}

function initDictionaryConfig() {
  const dictionary = {
    en: {
      custom: {
        // new_password: {
        //   is_not: 'New Password cannot be the same as current password',
        // },
        // confirm_new_password: {
        //   confirmed: 'Your password and confirmation password do not match',
        // },
        // confirm_password: {
        //   confirmed: 'Your password and confirmation password do not match',
        // },
        // duration: {
        //   min_value: (field, value) => {
        //     return `Duration must be ${value} minutes or more.`;
        //   },
        // },
        // order_size: {
        //   max_value() {
        //     return 'Your balance is not enough';
        //   },
        // },
        // order_total: {
        //   min_value: (field, value) => {
        //     return `Minimum value is ${value}`;
        //   },
        //   max_value: (field, value) => {
        //     return `Maximum value is ${value}`;
        //   },
        // },
      },
      messages: {
        // password: 'Invalid password',
        // email: 'Invalid email address.',
        // gt_value: (field: string, value: string) => {
        //   return field + ' must greater than ' + value + '.';
        // },
        // lt_value: (field: string, value: string) => {
        //   return field + ' must less than ' + value + '.';
        // },
        // required: (field: string) => {
        //   return field + ' is required.';
        // },
        // min: (field: string, value: string) => {
        //   return `${field} must contain at least 7 characters.`;
        // },
        // min_value: (field, value) => {
        //   return `Minimum value is ${value}`;
        // },
        // '2fa_code': (field: string) => {
        //   return 'Invalid 2FA code';
        // },
        // erc20_address: (field: string) => {
        //   return 'Invalid ERC20 address';
        // },
        // nem_address: () => {
        //   return 'Invalid NEM address';
        // },
      },
    },
  };

  return dictionary;
}
