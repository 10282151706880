import { http } from './http';
import {
  PlanDetail,
  AccountPlanDetail,
  PreviewOrder,
  Order,
} from '@/dtos/Account';

export function listPlan(): Promise<PlanDetail[]> {
  return http
    .callApi({
      resource: 'plan.list',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(e => e.data);
}

export function accountPlan(): Promise<AccountPlanDetail[]> {
  return http
    .callApi({
      resource: 'plan.current',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(e => e.data);
}

export function previewPlan(
  currencies: Record<string, number>,
  duration: number = 0,
): Promise<PreviewOrder> {
  return http
    .callApi({
      resource: 'plan.preview',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          addresses: currencies,
          ...(duration ? { duration } : {}),
        },
      },
    })
    .then(e => e.data);
}
export function pendingPlan(): Promise<Order> {
  return http
    .callApi({
      resource: 'plan.pending',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(e => e.data);
}

export function updatePlan(
  currencies: Record<string, number>,
  duration: number = 0,
): Promise<PreviewOrder> {
  return http
    .callApi({
      resource: 'plan.update',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          addresses: currencies,
          ...(duration ? { duration } : {}),
        },
      },
    })
    .then(e => e.data);
}
