import { render, staticRenderFns } from "./SidebarHeaderV2.vue?vue&type=template&id=b4f766f8&scoped=true&lang=pug&"
import script from "./SidebarHeaderV2.vue?vue&type=script&lang=ts&"
export * from "./SidebarHeaderV2.vue?vue&type=script&lang=ts&"
import style0 from "./SidebarHeaderV2.vue?vue&type=style&index=0&id=b4f766f8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4f766f8",
  null
  
)

export default component.exports