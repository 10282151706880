














































































































import {
  Component,
  Prop,
  Vue,
  Mixins,
  Watch,
  Provide,
} from 'vue-property-decorator';
import { State, Getter, Action } from 'vuex-class';
import ResponsiveMixin from '@/mixins/responsive';
import { mounted } from '@/utils/decorators/VueTimmer';
import { listPlan } from '@/apis/plan.api';
import { PlanDetail } from '@/dtos/Account';

@Component
export default class SidebarheaderV2 extends Mixins(ResponsiveMixin) {
  isCollapse = false;

  @Getter('account/pendingPlan')
  pendingPlan: any;

  @Provide()
  plans: Array<PlanDetail> = [];

  logout() {
    this.$router.push({ name: 'home' });
    this.$store.dispatch('account/logout');
  }

  @mounted
  getPlanList() {
    return listPlan().then(e => {
      this.plans = e;
    });
  }

  get walletLinks() {
    let list = [];
    this.plans.forEach(plan => {
      list.push({
        header: plan.currency_code,
        route: { name: 'wallet', params: { coin: plan.currency_code } },
      });
    });
    return list;
  }

  get paymentLinks() {
    return [
      ...(this.pendingPlan && this.pendingPlan.payment
        ? [
            {
              header: 'Pending',
              route: {
                name: 'payment_pending',
                query: { payment_id: this.pendingPlan.payment.payment_id },
              },
            },
          ]
        : []),
      { header: 'History', route: { name: 'payment_history' } },
      // { header: 'Payment detail', route: { name: 'payment_detail' } },
    ];
  }

  toggleCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  mounted() {
    if (this.isMobile) {
      this.isCollapse = false;
    }
  }

  @Watch('isMobile')
  onIsMobileChange(value: boolean) {
    if (value) {
      this.isCollapse = false;
    }
  }
}
