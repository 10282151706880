
























import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './styles/style.styl';
import { once } from 'lodash';

let onceScrollToHash = once(scrollToHash);

export default Vue.extend({
  updated() {
    onceScrollToHash();
  },
});

function scrollToHash() {
  setTimeout(() => {
    if (window.location.hash) {
      window.location.href = window.location.hash;
    }
  }, 1);
}
