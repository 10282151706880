























import Vue from 'vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';
import { AccountDetail } from '@/dtos/Account';
import LandingLayoutHeaderPrimary from './LandingLayoutHeaderPrimary.vue';
import LandingLayoutFooter from './LandingLayoutFooter.vue';
@Component({
  components: {
    LandingLayoutHeaderPrimary,
    LandingLayoutFooter,
  },
})
export default class LandingLayout extends Vue {
  @State(e => (e.account && e.account.detail) || null)
  account!: AccountDetail;
}
