




















































import { Component, Vue, Provide } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { AccountDetail } from '@/dtos/Account';
import { accountLogout } from '@/apis/account.api';

@Component
export default class CentralHeader extends Vue {
  @State(e => e.account && e.account.detail || null)
  account!: AccountDetail;

  @Action("account/logout")
  async logout(){}

  @Provide()
  toggleMenu = false

  onLogout(){
    this.logout()
      .then(() => {
        this.$router.replace({name: "login"})
      })
  }
}
