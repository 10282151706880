import _ from 'lodash';
import Vue from 'vue';

Vue.filter('toLocaleString', (value) => {
  return !value
    ? value
    : value.toLocaleString();
});
Vue.filter('toLocaleDate', (value) => {
  return !value
    ? value
    : value.toLocaleDateString();
});

Vue.filter('timeFormat', (value) => {
  let minute = (value / 60 / 1000) | 0
  let second = value % (60 * 1000) / 1000 | 0
  return `${minute.toString().padStart(2, "0")}:${second.toString().padStart(2, "0")}`
});
