import Vue from 'vue'
import { DeepPartial } from '@/dtos/Helper';
/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export default function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function vueMergeDeep<T extends {}>(target: T, ...sources: DeepPartial<T>[]) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key])
          Vue.set(target, key, {})
        vueMergeDeep(target[key], source[key]);
      } else {
        Vue.set(target, key, source[key])
      }
    }
  }

  return vueMergeDeep(target, ...sources);
}