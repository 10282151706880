

















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  props: {
    onClickMenu: {
      type: Function,
      default: () => {},
    }
  }
})
export default class CentralHeaderV2 extends Vue {
  onClickMenu: Function;

  clickMenu() {
    this.onClickMenu();
  }
}
