import Vue, { VNode, VNodeDirective } from 'vue';
import _ from 'lodash';

const state = new WeakMap()
// const state2 = new WeakMap()


Vue.directive('onscrollto', {
  bind(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {

    var isActive = false;
    const callback = _.debounce(
      async (event) => {
        await new Promise(requestAnimationFrame);
        var { bottom, height } = el.getBoundingClientRect()
        if (isActive != bottom <= innerHeight) {
          if (!isActive) {
            try {
              binding.value()
            } catch (error) {
              console.error(error)
            }
          } else { }
          isActive = bottom <= innerHeight;
        }
      },
      10
    )

    window.addEventListener(
      "scroll",
      callback,
    );

    var _interval = setInterval(callback, 2000)

    state.set(el, { callback, _interval });
  },
  update(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {

  },
  unbind(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {
    state.get(el) && window.removeEventListener(
      "scroll",
      state.get(el).callback,
    );
    state.get(el) && clearInterval(state.get(el)._interval)
  },
});


Vue.directive('onscrolltotop', {
  bind(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {

    var isActive = false;
    const callback = _.debounce(
      async (event) => {
        await new Promise(requestAnimationFrame);
        var { top, height } = el.getBoundingClientRect()
        if (isActive != top >= 0) {
          if (!isActive) {
            try {
              binding.value()
            } catch (error) {
              console.error(error)
            }
          } else { }
          isActive = top >= 0;
        }
      },
      10
    )

    window.addEventListener(
      "scroll",
      callback,
    );

    var _interval = setInterval(callback, 2000)

    state.set(el, { callback, _interval });
  },
  update(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {

  },
  unbind(el: HTMLElement, binding: VNodeDirective, vnode: VNode) {
    state.get(el) && window.removeEventListener(
      "scroll",
      state.get(el).callback,
    );
    state.get(el) && clearInterval(state.get(el)._interval)
  },
});