import Vue from 'vue';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

Vue.filter('toDateJs', function(value) {
  return new Date(value);
});
dayjs.extend(relativeTime)

Vue.filter('fromNow', function(value) {
  return dayjs(value).fromNow()
});
