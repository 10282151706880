import CentralLayout from '@/components/layouts/centralLayout/CentralLayout.vue';
import _ from 'lodash';
import { RouteConfig } from 'vue-router';

export default function init(routes: RouteConfig[]) {
  const defaultRoute: RouteConfig = {
    path: '/docs',
    component: CentralLayout,
    children: [
      {
        path: 'overview',
        name: 'docs_overview',
        component: () =>
          import(
            './docs/DocsOverviewPage.vue'
          ),
        meta: {
          title:
            'Docs - Overview | Crypitor - Monitoring The Crypto World',
          requiresAuth: true,
        },
      },
      {
        path: 'guides',
        name: 'docs_guides',
        component: () =>
          import(
            './docs/DocsGuidesPage.vue'
          ),
        meta: {
          title:
            'Docs - Guides | Crypitor - Monitoring The Crypto World',
          requiresAuth: true,
        },
      },
      {
        path: 'webhook',
        name: 'docs_webhook',
        component: () =>
          import(
            './docs/DocsWebhookPage.vue'
          ),
        meta: {
          title:
            'Docs - Webhook | Crypitor - Monitoring The Crypto World',
          requiresAuth: true,
        },
      },
      {
        path: 'sample',
        name: 'docs_sample',
        component: () =>
          import(
            './docs/DocsSamplePage.vue'
          ),
        meta: {
          title:
            'Docs - Sample | Crypitor - Monitoring The Crypto World',
          requiresAuth: true,
        },
      }
    ],
  };

  routes.push(defaultRoute);
}
