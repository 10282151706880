import Vue from 'vue';
import Router, { Route, RouteConfig, RouterOptions } from 'vue-router';
import store, { init as initStore } from './store/index';

Vue.use(Router);
const routes = buildRoutes();
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  scrollBehavior: getScrollBehaviour(),
});

const initStorePromise = initStore(router);
guardNagivation();

updateTitlePage();
export default router;

function buildRoutes(): RouteConfig[] {
  const vueRoutes: RouteConfig[] = [];
  const pagesRoutes = require.context(
    './components/pages',
    true,
    /\.route\.ts$/,
  );
  const keys = pagesRoutes.keys();

  keys.forEach((key: string) => {
    pagesRoutes(key).default(vueRoutes);
  });

  vueRoutes.push({
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "NotFoundPage" */ './components/pages/notFound/NotFoundPage.vue'
      ),
    meta: {
      title: 'Not Found Page',
    },
  });

  return vueRoutes;
}

function guardNagivation() {
  router.beforeEach((to, from, next) => {
    initStorePromise
      .then(() => {
        let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

        if (!requiresAuth || store.state.account.detail) {
          if (!store.state.account.detail) {
            store.dispatch('account/tryGetProfile');
          }

          next();
          return null;
        }

        store.dispatch('account/tryGetProfile').then(() => {
          if (!store.state.account.detail) {
            next({ name: 'login', query: { redirect: to.fullPath } });
            return null;
          }

          next({});
          return null;
        });
      })
      .catch(next);
  });
}

function getScrollBehaviour() {
  // scrollBehavior:
  // - only available in html5 history mode
  // - defaults to no scroll behavior
  // - return false to prevent scroll
  const scrollBehavior = function(to, from, savedPosition) {
    if (to.path === from.path) {
      return;
    }

    if (savedPosition) {
      // savedPosition is only available for popstate navigations.
      return savedPosition;
    } else {
      const position: any = {};

      // scroll to anchor by returning the selector
      if (to.hash) {
        position.selector = to.hash;

        // specify offset of the element
        if (to.hash === '#anchor2') {
          position.offset = { y: 100 };
        }

        if (document.querySelector(to.hash)) {
          return position;
        }

        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        return false;
      }
      position.x = 0;
      position.y = 0;
      return position;
      // return new Promise(resolve => {
      //   // check if any matched route config has meta that requires scrolling to top
      //   if (to.matched.some(m => m.meta.scrollToTop)) {
      //     // coords will be used if no selector is provided,
      //     // or if the selector didn't match any element.
      //     position.x = 0;
      //     position.y = 0;
      //   }

      //   resolve(position);
      //   // // wait for the out transition to complete (if necessary)
      //   // this.app.$root.$once('triggerScroll', () => {
      //   //   // if the resolved position is falsy or an empty object,
      //   //   // will retain current scroll position.
      //   //   resolve(position);
      //   // });
      // });
    }
  };

  return scrollBehavior;
}

function updateTitlePage() {
  // check change state and set title for page

  router.afterEach(route => {
    document.title = route.meta.title;
  });
}
