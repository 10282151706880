import axios from 'axios';

// export const baseURL = "http://localhost:8082"
export const baseURL = 'https://testnet.crypitor.com/web';
// export const baseURL = "https://crypitor.com/web"

const instance = axios.create({
  // baseURL: process.env.BASE_API_URL || window.location.origin,
  baseURL,
  withCredentials: true,
});

instance.interceptors.request.use(config => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  return config;
});

export default instance;
