import Vue from 'vue';
import { RootState } from '../state';
import { ActionContext, Module } from 'vuex';
import { AccountDetail, PreviewOrder, Payment } from '@/dtos/Account';
import { vueMergeDeep } from '@/utils/mergeDeep';
import { accountDetail, accountLogin, accountLogout } from '@/apis/account.api';
import { DeepPartial } from '@/dtos/Helper';
import { pendingPlan } from '@/apis/plan.api';
import { getPaymentDetail } from '@/apis/payment.api';
// import { getPairConfigs, getConfigs } from '@/apis/config.api';

export interface IAccountStoreState {
  detail: AccountDetail;
  pendingPlan: PreviewOrder;
  payments: Record<string, Payment>;
  deltaTime: number;
}

enum MUTATION_TYPE {
  UPDATE = 'UPDATE',
}

const State: IAccountStoreState = {
  detail: null,
  pendingPlan: null,
  payments: {},
  deltaTime: 0,
};

const Mutation = {
  [MUTATION_TYPE.UPDATE](
    store: IAccountStoreState,
    data: DeepPartial<IAccountStoreState>,
  ) {
    vueMergeDeep(store, data);
  },
};

const Action = {
  login: async (
    context: ActionContext<IAccountStoreState, RootState>,
    { email, password },
  ) => {
    return accountLogin({ email, password }).then(({ data, error }) => {
      if (data) {
        context.commit(MUTATION_TYPE.UPDATE, {
          detail: data,
        });
        context.dispatch('getPendingPlan');
      }
      return { data, error };
    });
  },

  getPendingPlan(context: ActionContext<IAccountStoreState, RootState>) {
    return pendingPlan().then(pendingPlan => {
      if (pendingPlan) {
        context.commit(MUTATION_TYPE.UPDATE, { pendingPlan });
      }

      if (pendingPlan && pendingPlan.payment) {
        context.commit(MUTATION_TYPE.UPDATE, {
          payments: { [pendingPlan.payment.payment_id]: pendingPlan.payment },
        });
      }
      if (pendingPlan && pendingPlan.system_time) {
        context.commit(MUTATION_TYPE.UPDATE, {
          deltaTime: pendingPlan.system_time - Date.now(),
        });
      }
      return pendingPlan;
    });
  },

  getPaymentDetail(
    context: ActionContext<IAccountStoreState, RootState>,
    paymentId,
  ) {
    return getPaymentDetail(paymentId).then(payment => {
      context.commit(MUTATION_TYPE.UPDATE, {
        payments: { [payment.payment_id]: payment },
      });
    });
  },

  logout: async (context: ActionContext<IAccountStoreState, RootState>) => {
    return accountLogout().then(() => {
      context.commit(MUTATION_TYPE.UPDATE, {
        detail: null,
        pendingPlan: null,
        payments: {},
      });
    });
  },
  init: async (context: ActionContext<IAccountStoreState, RootState>) => {
    Promise.all([
      context.dispatch('tryGetProfile'),
      context.dispatch('getPendingPlan'),
    ]);

    return;
  },
  tryGetProfile(context: ActionContext<IAccountStoreState, RootState>) {
    return accountDetail().then(detail =>
      context.commit(MUTATION_TYPE.UPDATE, {
        detail,
      }),
    )
    .catch(() => {

    });
  },
};

const Getter = {
  pendingPlan(state: IAccountStoreState): any {
    return state.pendingPlan;
  },
  payments(state: IAccountStoreState): any {
    return state.payments;
  },
  deltaTime(state: IAccountStoreState): any {
    return state.deltaTime;
  },
  detail(state: IAccountStoreState) {
    return state.detail || null;
  },
} as any;

export default {
  state: State,
  mutations: Mutation,
  actions: Action,
  getters: Getter,
  namespaced: true,
} as Module<IAccountStoreState, RootState>;
