import Vue from 'vue';

Vue.filter('round', function (value, decimal) {
  return parseFloat(value).toFixed(decimal)
});


Vue.filter('abs', function (value, ) {
  return Math.abs(parseFloat(value))
});

Vue.filter('percent', function (value) {
  return Math.min(100, Math.max(value * 100, -100)).toFixed(2) + ' %'
});


