import { http } from './http';
import _ from 'lodash';

export function getPaymentDetail({payment_id}) {
  let body = _.assign(
    {
      payment_id: payment_id,
    },
  );

  return http
    .callApi({
      resource: 'crypitor_payment.detail',
      data: body,
    })
    .then(e => e.data);
}
