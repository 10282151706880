import axios from './axios';
import { AxiosPromise } from 'axios';
import _ from 'lodash';

const http = {
  client: axios,
  callApi,
};

const apiResources: any = {
  account: {
    register: {
      verb: 'POST',
      url: '/account/register',
    },
    resend: {
      verb: 'POST',
      url: '/account/register/resend',
    },
    login: {
      verb: 'POST',
      url: '/j_spring_security_check',
    },
    logout: {
      verb: 'POST',
      url: '/j_spring_security_logout',
    },
    detail: {
      verb: 'GET',
      url: '/account/details',
    },
    forgot_password: {
      verb: 'GET',
      url: '/account/forgot-pwd',
    },
    reset_password: {
      verb: 'GET',
      url: '/account/reset-pwd',
    },
    change_passwiord: {
      verb: 'GET',
      url: '/account/change-pwd',
    },
    activate: {
      verb: 'POST',
      url: '/account/activate/by-code',
    },
  },
  plan: {
    current: {
      verb: 'GET',
      url: '/account/plan/list',
    },
    list: {
      verb: 'GET',
      url: '/coins/list',
    },
    preview: {
      verb: 'POST',
      url: '/account/plan/preview',
    },
    pending: {
      verb: 'GET',
      url: '/account/plan/pending',
    },
    update: {
      verb: 'POST',
      url: '/account/plan/update',
    },
  },
  payment: {
    detail: {
      verb: 'POST',
      url: '/account/payment/pending/detail',
    },
    pendings: {
      verb: 'POST',
      url: '/account/payment/pending/list',
    },
    history: {
      verb: 'POST',
      url: '/account/payment/history',
    },
  },
  hook: {
    add: {
      verb: 'POST',
      url: '/account/webhook/create',
    },
    remove: {
      verb: 'POST',
      url: '/account/webhook/remove',
    },
    list: {
      verb: 'POST',
      url: '/account/webhook/list',
    },
    detail: {
      verb: 'GET',
      url: '/account/webhook/find?address={address}&coin={coin}'
    },
    transaction: {
      verb: 'POST',
      url: '/account/webhook/transaction/list'
    }
  },
  password: {
    forgot: {
      verb: 'POST',
      url: '/account/forgot-pwd',
    },
    reset: {
      verb: 'POST',
      url: '/account/forgot-pwd/reset',
    },
    change: {
      verb: 'POST',
      url: '/account/change-pwd',
    },
  },
  twoFa: {
    generate: {
      verb: 'POST',
      url: '/account/2fa/gen',
    },
    enable: {
      verb: 'POST',
      url: '/account/2fa/enable',
    },
    disable: {
      verb: 'POST',
      url: '/account/2fa/disable',
    },
    verify: {
      verb: 'POST',
      url: '/account/2fa/verify',
    }
  },
  customer: {
    contactus: {
      verb: 'POST',
      url: '/customer/ticket/submit'
    }
  },
  apiKey: {
    create: {
      verb: 'POST',
      url: '/account/api-key/create',
    },
    list: {
      verb: 'GET',
      url: '/account/api-key/list',
    },
    delete: {
      verb: 'DELETE',
      url: '/account/api-key/delete',
    },
    listRoles: {
      verb: 'GET',
      url: '/account/api-key/roles',
    }
  },
  report: {
    transaction: {
      verb: 'GET',
      url: '/account/report/transactions?currency={currency}&address={address}&group_by={group_by}&from={from}&to={to}',
    },
    coin: {
      verb: 'GET',
      url: '/account/report/coins?from={from}&to={to}',
    },
  },
  system: {
    mylocation: {
      verb: 'GET',
      url: '/my-location'
    }
  },
  crypitor_payment: {
    detail: {
      verb : 'GET',
      url : 'https://testnet.crypitor.com/payment/v1/order/detail?payment_id={payment_id}'
    }
  }
};

export { http };

interface CallApiParams {
  resource: string;
  data?: object;
  headers?: object;
  config?: object;
  query?: object;
}

function callApi(params: CallApiParams): AxiosPromise {
  const apiSplit = params.resource.split('.');
  const module = apiSplit[0];
  const method = apiSplit[1];
  const fullResource = apiResources[module][method];
  let url: string = fullResource.url;
  const data: any = params.data || {};
  const urlParams = _.omit(data, ['body']);
  const headers =
    fullResource.headers || params.headers
      ? _.assign(
          {},
          axios.defaults.headers,
          fullResource.headers,
          params.headers,
        )
      : axios.defaults.headers;

  _.forIn(urlParams, (value, key) => {
    if (value) {
      url = url.replace('{' + key + '}', value);
    } else {
      const reg = new RegExp(key + '={' + key + '}&?');
      url = url.replace(reg, '');
    }
  });

  const query = Object.entries(params.query || {})
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  url = url.replace(/[&\?]$/, '') + (query ? '?' + query : '');
  const config = _.assign(
    {
      method: fullResource.verb,
      url,
      data: data.body,
      headers,
    },
    params.config,
  );

  return axios.request(config).catch(e => {
    throw e.response;
  });
}
