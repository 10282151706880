import Vue from 'vue';
import { RootState } from '../state';
import { ActionContext, Module } from 'vuex';
import { DeepPartial } from '@/dtos/Helper';
import { vueMergeDeep } from '@/utils/mergeDeep';
import {CrypitorPayment} from '@/dtos/CrypitorPayment';
import {getPaymentDetail} from '@/apis/crypitor_payment.api';

enum MUTATION_TYPE {
  UPDATE = 'UPDATE',
}

export interface ICrypitorPaymentStoreState {
  payment: CrypitorPayment,
  deltaTime: number;
}

const State: ICrypitorPaymentStoreState = {
  payment: null,
  deltaTime: 0,
};

const Mutation = {
  [MUTATION_TYPE.UPDATE](
    store: ICrypitorPaymentStoreState,
    data: DeepPartial<ICrypitorPaymentStoreState>,
  ) {
    vueMergeDeep(store, data);
  },
};

const Action = {
  getPaymentDetail(
    context: ActionContext<ICrypitorPaymentStoreState, RootState>,
    paymentId,
  ) {
    return getPaymentDetail({payment_id: paymentId}).then(payment => {
      context.commit(MUTATION_TYPE.UPDATE, {
        payment: payment,
        deltaTime: payment.server_time - Date.now(),
      });
    });
  },
}

const Getter = {
  payment(state: ICrypitorPaymentStoreState): any {
    return state.payment;
  },
  deltaTime(state: ICrypitorPaymentStoreState): any {
    return state.deltaTime;
  },
}

export default {
  state: State,
  mutations: Mutation,
  actions: Action,
  getters: Getter,
  namespaced: true,
} as Module<ICrypitorPaymentStoreState, RootState>;