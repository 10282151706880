<template lang="pug">
  div.content-option-menu
    li.headerLink(v-if='!childrenLinks && isHeader', :class='className')
      router-link(:to='link')
        .icon.d-flex.align-items-center
          img.img-icon(:src="logoImg" alt="Responsive image")
          | {{header}} 
    li.headerLink(v-else-if='childrenLinks', :class='className')
      div.my-2(@click='() => togglePanelCollapse(link)')
        router-link.d-flex(:to='link', event='')
          .icon.title-sub-menu.d-flex.align-items-center
            img.img-icon(:src="logoImg" alt="Responsive image")
            | {{header}} 
          .icon
            img.img-icon(src="../assets/arrow-point-to-up.png" alt="Responsive image" v-if="isActive")
            img.img-icon(src="../assets/arrow-point-to-down.png" alt="Responsive image" v-else="")
      b-collapse(:id="'collapse' + index", :visible='isActive')
        ul
          NavLink(v-for='link in childrenLinks', :activeItem='activeItem', :header='link.header', :index='link.index', :link='link.link', :childrenLinks='link.childrenLinks', :key='link.link')
    li.child-li(v-else='' @mouseover="onMouseOver" @mouseleave="onMouseOut")
      router-link.d-flex.align-items-center(:to="index !== 'menu' && link")
        img.img-icon.child-style-icon(:src='logoNavLink' alt="Responsive image")
        | {{header}} 
        sup.headerLabel(v-if='label') {{label}}
</template>

<style lang="stylus" scoped>
@import '~@/styles/variables'

ul
  list-style-type none
  padding-left 0px

ul li.child-li
  cursor pointer

ul li.child-li:hover
  color blue-azure-radiance

ul li.child-li:hover a
  color blue-azure-radiance !important

// .child-style-icon
// padding 2px
.content-option-menu:hover
  background blue-san-juan
  border-left 3px solid blue-dodger

.content-option-menu
  color white
  padding 10px 15px
  border-left 3px solid transparent

  .headerLink a
    color white

  .headerLink a:hover
    text-decoration none

  .headerLink a:not(.d-flex):hover
    color blue-azure-radiance

  .title-sub-menu
    width 90%

  .img-icon
    vertical-align unset !important
    margin-right 15px

  .collapse .content-option-menu:hover
    border-left 3px solid transparent
</style>

<script>
import { mapActions } from "vuex";

export default {
  name: "NavLink",
  props: {
    badge: { type: String, dafault: "" },
    header: { type: String, default: "" },
    iconName: { type: String, default: "" },
    headerLink: { type: String, default: "" },
    link: { type: String, default: "" },
    childrenLinks: { type: Array, default: null },
    className: { type: String, default: "" },
    isHeader: { type: Boolean, default: false },
    deep: { type: Number, default: 0 },
    activeItem: { type: String, default: "" },
    label: { type: String },
    index: { type: String },
    logoImg: { type: String, dafault: "" }
  },
  data() {
    let logoInside = require("../assets/logo-inside.png");
    let logoInsideNon = require("../assets/logo-inside-non.png");
    return {
      headerLinkWasClicked: true,
      logoInside,
      logoInsideNon,
      isHovering: false
    };
  },
  methods: {
    // ...mapActions('layout', ['changeSidebarActive']),
    togglePanelCollapse(link) {
      // this.$emit('updateActiveItem', link);
      // this.changeSidebarActive(link);
      // this.headerLinkWasClicked = !this.headerLinkWasClicked
      // || !this.activeItem.includes(this.index);

      this.headerLinkWasClicked = !this.headerLinkWasClicked;
    },
    onMouseOver() {
      this.isHovering = true;
    },
    onMouseOut() {
      this.isHovering = false;
    }
  },
  computed: {
    fullIconName() {
      return `fi ${this.iconName}`;
    },
    isActive() {
      // return (this.activeItem
      // && this.activeItem.includes(this.index)
      // && this.headerLinkWasClicked);
      return this.headerLinkWasClicked;
    },
    logoNavLink() {
      return this.isHovering ? this.logoInside : this.logoInsideNon;
    }
  }
};
</script>

