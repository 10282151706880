




























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';
import { AccountDetail } from '@/dtos/Account';

@Component({
  components: {},
})
export default class LandingLayoutHeaderPrimary extends Vue {
  @State(e => (e.account && e.account.detail) || null)
  account!: AccountDetail;

  isPrimaryHeader = true;
  isShowingCollapse = false;

  get isHomePrimaryHeader() {
    return this.$route.name == 'home' && this.isPrimaryHeader && !this.isShowingCollapse;
  }

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    this.isPrimaryHeader = currentScrollPosition < 300;
  }
}
