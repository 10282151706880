




















































import NavLink from './NavLink.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Getter, Action } from 'vuex-class';
import { AccountDetail } from '../../../../dtos/Account';
@Component({
  components: {
    NavLink,
  },
})
export default class SidebarHeader extends Vue {
  activeItem = '';

  @State(e => (e.account && e.account.detail) || null)
  account!: AccountDetail;

  @Action('account/logout')
  logout() {}

  @Getter('account/pendingPlan')
  pendingPlan: any;

  async logoutClick() {
    await this.logout();
    await this.$router.replace({ name: 'login' });
  }

  get projectId() {
    return this.$route.params.projectId;
  }

  get dashBoardLinks() {
    return [
      { header: 'Account Detail', link: `/account/detail` },
      { header: 'API Keys', link: `/account/apikeys` },
      { header: 'Plan', link: `/account/plan` },
    ];
  }

  get paymentLinks() {
    return [
      ...(this.pendingPlan && this.pendingPlan.payment
        ? [
            {
              header: 'Pending',
              link: `/payment/pending?payment_id=${this.pendingPlan.payment.payment_id}`,
            },
          ]
        : []),
      { header: 'History', link: `/payment/history` },
      { header: 'Payment detail', link: `/payment/detail` },
    ];
  }

  get walletLinks() {
    return [
      // { header: 'BTC', route: { name: 'wallet', params: { coin: 'BTC' } } },
      { header: 'ETH', route: { name: 'wallet', params: { coin: 'ETH' } } },
      // { header: 'XEM', route: { name: 'wallet', params: { coin: 'XEM' } } }
    ];
  }

  onUpdateActiveItem(link) {
    this.activeItem = link;
  }

  logoHome = require('../assets/home.png');
  logoBot = require('../assets/robot-02.png');
  logoConfiguration = require('../assets/config.png');
  logoRunning = require('../assets/solar-system.png');
  logoCreateBot = require('../assets/create.png');
  logoPair = require('../assets/robot-02.png');
  logoTeam = require('../assets/User.png');
}
