import PaymentLayout from '@/components/layouts/paymentLayout/PaymentLayout.vue';
import _ from 'lodash';
import { RouteConfig, Route, RawLocation } from 'vue-router';

const Deposit = () =>
  import(/*webpackChunkName: "Deposit"*/ './pay/Deposit.vue');

export default function init(routes: RouteConfig[]) {
  const defaultRoute: RouteConfig = {
    path: '/pay',
    component: PaymentLayout,
    children: [
      {
        path: '',
        name: 'deposit',
        component: Deposit,
        meta: {
          title:
            'Payment | Crypitor -  Monitoring The Crypto World',
          requiresAuth: false,
        },
      },
    ],
  };

  routes.push(defaultRoute);
}
