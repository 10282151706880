
declare interface Array<T> {
  sortBy(e: (f: T) => number): Array<T>;
  sum(e: (f: T) => number): number;
  sum<T extends number>(): number;
  toObjectBy<E extends string | number>(e: (f: T) => E): Record<E, T>;
  groupBy<E extends string | number>(e: (f: T) => E): Record<E, T>;
  combineWith(e: ((f : T) => any), r : (e: T,f : T) => T):  Array<T>;
}



Object.defineProperty(Array.prototype, 'sortBy', {
  enumerable: false,
  value: function <T>(func: (f: T) => number) {
    return this
      .map((e, i) => ([func(e), i]))
      .sort((e, f) => e[0] - f[0])
      .map(e => this[e[1]])
  }
});

Object.defineProperty(Array.prototype, 'sum', {
  enumerable: false,
  value: function <T>(func?: (f: T) => number) {
    if (func)
      return this.map(func).reduce((e, f) => e + f, 0)
    return this.reduce((e, f) => e + f, 0)
  }
});

Object.defineProperty(Array.prototype, 'toObjectBy', {
  enumerable: false,
  value: function <T, E extends string | number>(func?: (f: T) => E): Record<E, T> {
    let obj: Record<E, T> = <any>{}
    for (let ob of this)
      obj[func(ob)] = ob
    return obj
  }
});

Object.defineProperty(Array.prototype, 'groupBy', {
  enumerable: false,
  value: function <T, E extends string | number>(func?: (f: T) => E): Record<E, T[]> {
    let obj: Record<E, T[]> = <any>{}
    for (let ob of this){
      let key = func(ob);
      (obj[key] || (obj[key] =[])).push(ob)
    }
    return obj
  }
});

Object.defineProperty(Array.prototype, 'combineWith', {
  enumerable: false,
  value: function <T> (compare: ((f : T) => any), reduce : (e: T,f : T) => T):  Array<T> {
    let result = []
    for(let value of this){
      let last =result[result.length - 1]
      if(!last || compare(last) != compare(value)){
        result.push(value)
      }else{
        result[result.length - 1] = reduce(last,value)
      }
    }
    return result
  }
});


