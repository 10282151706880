






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { AccountDetail } from '../../../dtos/Account';

@Component
export default class AuthenLayout extends Vue {


  @State(e => e.account && e.account.detail || null)
  account!: AccountDetail;

  @Watch("account.email")
  onAccountAlreadyLogin(email){
    if(!email){
      this.$router.replace({name:"account_detail"})
    }
  }
}
