import Vue from 'vue'
import { createDecorator } from 'vue-class-component'
import { get, set } from "lodash"



export const mounted = createDecorator((componentOptions, key) => {
  let mounted: Function | undefined = componentOptions.mounted;
  let methods: any = componentOptions.methods;
  let handler: Function = methods[key];
  componentOptions.mounted = function (this: any): void {
    if (typeof mounted === "function")
      mounted.call(this);

    handler.call(this)
  };
})

export const beforeDestroy = createDecorator((componentOptions, key) => {
  let beforeDestroy: Function | undefined = componentOptions.beforeDestroy;
  let methods: any = componentOptions.methods;
  let handler: Function = methods[key];

  componentOptions.beforeDestroy = function (this: any): void {
    if (typeof beforeDestroy === "function")
      beforeDestroy.call(this);
    handler.call(this)
  };

})



export const loadings = (f: ((...arg) => string) | string) => createDecorator((componentOptions, key) => {
  let methods: any = componentOptions.methods;
  let handler: Function = methods[key];

  methods[key] = function (...args) {
    let path = (typeof f == 'string') ? f : f(...args)
    let [key, ...root] = path.split('.').reverse()
    let rootOb = root.length ? get(this, root.reverse(), {}) : this
    Vue.set(rootOb, key, true)
    return handler
      .call(this, ...args)
      .finally(() => Vue.set(rootOb, key, false))
  };
})


export const debounce = (delay: number) => createDecorator((componentOptions, key) => {
  let methods: any = componentOptions.methods;
  let handler: Function = methods[key];
  let timeoutSym = Symbol()
  methods[key] = function (...args) {
    clearTimeout(this[timeoutSym])
    this[timeoutSym] = setTimeout(
      handler.bind(this, ...args),
      delay 
    )
  };
})
export const popupLoadings = (message: string) => createDecorator((componentOptions, key) => {
  let methods: any = componentOptions.methods;
  let handler: Function = methods[key];

  methods[key] = function (...args) {
    let p = handler.call(this, ...args)
    return this.$popupLoading(
      sleep(500)
        .then(() => p),
      message
    )
  };
})


export const errorToastHandler = (f: ((...arg) => string) | string) => createDecorator((componentOptions, key) => {
  let methods: any = componentOptions.methods;
  let handler: Function = methods[key];

  methods[key] = function (...args) {
    let message = (typeof f == 'string') ? f : f(this, ...args)
    return handler
      .call(this, ...args)
      .catch((error) => {
        this.$toastError(error, message)
        throw error
      })
  };
})

export const errorPopupHandler = (f: ((...arg) => string) | string) => createDecorator((componentOptions, key) => {
  let methods: any = componentOptions.methods;
  let handler: Function = methods[key];

  methods[key] = function (...args) {
    let message = (typeof f == 'string') ? f : f(this, ...args)
    return handler
      .call(this, ...args)
      .catch((error) => {
        this.$popupError(error, message)
        throw error
      })
  };
})

export const successPopupHandler = (f: ((...arg) => string) | string) => createDecorator((componentOptions, key) => {
  let methods: any = componentOptions.methods;
  let handler: Function = methods[key];

  methods[key] = function (...args) {
    let message = (typeof f == 'string') ? f : f(this, ...args)
    let p = handler
      .call(this, ...args)
    Promise
      .resolve(p)
      .then(() => this.$popupSuccess(message))
    return p

  };
})

export const errorCatchHandler = (field: string, defaultMessage = "Somethings went wrong!", ...watch: string[]) => createDecorator((componentOptions, key) => {
  let methods: any = componentOptions.methods;
  let handler: Function = methods[key];

  let mounted = componentOptions.mounted

  componentOptions.mounted = function () {
    if (typeof mounted == "function")
      mounted.apply(this)
    for (let watchField of watch)
      this.$watch(watchField, () => set(this, field, ""), { deep: true })
  }

  methods[key] = function (...args) {
    return handler
      .call(this, ...args)
      .catch((error) => {
        let errorText = ""
        errorText = errorText || get(error, "data.description")
        errorText = errorText || get(error, "msg")
        errorText = errorText || get(error, "message")
        errorText = errorText || get(error, "statusText")
        errorText = errorText || get(error, "data")
        errorText = errorText || defaultMessage
        set(this, field, errorText)
        throw error
      })
  };
})

export function runInterval(interval = 1000) {

  return createDecorator((componentOptions, key) => {
    let symbol = Symbol('interval_' + key)

    let mounted: Function | undefined = componentOptions.mounted;
    let beforeDestroy: Function | undefined = componentOptions.beforeDestroy;

    componentOptions.mounted = function (this: any): void {
      if (typeof mounted === "function")
        mounted.call(this);
      this[symbol] = setInterval(() => this[key](), interval)
    };

    componentOptions.beforeDestroy = function (this: any): void {
      if (typeof beforeDestroy === "function")
        beforeDestroy.call(this);
      clearInterval(this[symbol])
    };

  })

}

const sleep = t => new Promise(r => setTimeout(r, t))

export function runLoop(delay = 10000) {

  return createDecorator((componentOptions, key) => {
    let symbol = Symbol('loop_' + key)

    let mounted: Function | undefined = componentOptions.mounted;
    let beforeDestroy: Function | undefined = componentOptions.beforeDestroy;

    componentOptions.mounted = function (this: any): void {

      if (typeof mounted === "function")
        mounted.call(this);
      this[symbol] = true
      setTimeout(async () => {
        while (this[symbol]) {
          try {
            await this[key]()
          } catch (error) {
            await sleep(delay * 10)
            console.error(error)
          }
          await sleep(delay)
          await new Promise(requestAnimationFrame)
        }
      })
    };

    componentOptions.beforeDestroy = function (this: any): void {
      if (typeof beforeDestroy === "function")
        beforeDestroy.call(this);
      this[symbol] = false
    };

  })

}


export function Subscribe<T>(
  sub: (e: T, set: (v: any) => void) => () => void
) {

  return createDecorator((componentOptions, key) => {
    let symbol = Symbol('subscribe_' + key)

    let mounted: Function | undefined = componentOptions.mounted;
    let beforeDestroy: Function | undefined = componentOptions.beforeDestroy;

    componentOptions.mounted = function (this: any): void {
      if (typeof mounted === "function")
        mounted.call(this);

      let setFunc = value => Vue.set(this, key, value)
      this[symbol] = sub(this, setFunc)
    };

    componentOptions.beforeDestroy = function (this: any): void {
      if (typeof beforeDestroy === "function")
        beforeDestroy.call(this);
      this[symbol] && this[symbol]()
    };

  })

}


export function ReactiveSubscribe<T, E = T>(
  dep: (e: T) => E,
  sub: (e: E, set: (v: any) => void, get: () => any) => () => void
) {

  return createDecorator((componentOptions, key) => {
    let symbol = 'reactive_subscribe_' + key
    let subSymbol = Symbol(symbol)

    let mounted: Function | undefined = componentOptions.mounted;
    let beforeDestroy: Function | undefined = componentOptions.beforeDestroy;

    if (!componentOptions.computed)
      componentOptions.computed = {}

    if (!componentOptions.watch)
      componentOptions.watch = {}

    componentOptions.computed[symbol] = function () {
      return dep(this)
    }

    componentOptions.watch[symbol] = function (newValue, oldValue) {
      this[subSymbol] && this[subSymbol]()
      this[subSymbol] = sub(
        this[symbol],
        value => Vue.set(this, key, value),
        () => this[key]
      )
    }

    componentOptions.mounted = function (this: any): void {
      if (typeof mounted === "function")
        mounted.call(this);
      this[subSymbol] = sub(
        this[symbol],
        value => Vue.set(this, key, value),
        () => this[key]
      )
    };

    componentOptions.beforeDestroy = function (this: any): void {
      if (typeof beforeDestroy === "function")
        beforeDestroy.call(this);
      this[subSymbol] && this[subSymbol]()
    };

  })

}
